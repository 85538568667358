import { ziasAxios, login } from '../Api'

export const authenticate = (un, pass, cbsf, cbef) => {
  const data = {
    auditorNumber: un,
    password: pass,
  }
  ziasAxios('POST', login, data, cbsf, cbef)
}

export const logout = async () => {
  const promise = new Promise((resolve) => setTimeout(resolve, 500))
  await promise
  localStorage.removeItem('ZioagAuthData')
  console.log('Successfully logged out!')
}

export const getAuth = () => {
  return JSON.parse(localStorage.getItem('ZioagAuthData'))
}
